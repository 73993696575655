import React, { Fragment } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Profile from "./Profile";
import logo from "images/biobela_small_light_white.png";
import { classNames } from "./utils";

const Navbar = ({ enableUsers, navElements }) => {
  const DesktopNavElements = () => {
    return (
      <div className="hidden sm:block sm:ml-10">
        <div className="flex space-x-4 items-center">
          {navElements.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                item.current
                  ? "font-bold text-yellow-200"
                  : "text-gray-100 hover:bg-green-900 hover:bg-opacity-90",
                "px-3 py-2 rounded-md text-md font-medium",
              )}
              aria-current={item.current ? "page" : undefined}
              data-turbolinks="false"
            >
              {item.label}
            </a>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Disclosure
      as="nav"
      className="primary bg-gradient-to-b from-green-800 to-lime-700"
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton className="inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-white hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <a href="/">
                    <img
                      className="block h-8 w-auto"
                      src={logo}
                      alt="Workflow"
                    />
                  </a>
                </div>
                {<DesktopNavElements />}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                {enableUsers && <Profile />}
              </div>
            </div>
          </div>

          {/* Mobile menu, show/hide based on menu state. */}
          <DisclosurePanel className="sm:hidden z-30">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navElements.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "font-bold text-yellow-200"
                      : "text-gray-100 hover:bg-green-900 hover:bg-opacity-90",
                    "block px-3 py-2 rounded-md text-base font-medium",
                  )}
                  aria-current={item.current ? "page" : undefined}
                  data-turbolinks="false"
                >
                  {item.label}
                </a>
              ))}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
