import React from 'react'

// import Swiper core and required modules
import {
    Pagination,
    Autoplay,
} from 'swiper/modules';

import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination"

export default function Carousel({images, classNames}) {

    let slides = images.map((imagePath) => {
        return (
            <SwiperSlide key={imagePath} style={{width: 'auto'}} >
                <img src={imagePath} className='w-auto h-full shadow-lg rounded-xl' loading='lazy'/>
            </SwiperSlide>
        )
    });

    return (
        <Swiper
            loop={true}
            lazy='true'
            modules={[Pagination, Autoplay]}
            slidesPerView={'auto'}
            spaceBetween={25}
            autoplay={{
                "delay": 3000,
                "disableOnInteraction": true
            }}
            speed={1000}
            pagination={{
                "clickable":
                    true
            }}
            className={classNames}>
            {slides}
        </Swiper>
    )
}
