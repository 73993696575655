import React, {Component} from 'react'

class App extends Component {
    render() {
        return(
        <div>Hello world, react app!</div>
        )
    }
}
export default App;
