import React from 'react'

// Receives working hours range for weekdays, saturday and sunday
const WorkTime = ({weekdays, sat, sun}) => {
    return(
        <ul className={'pt-2 border-t-0'}>
            {weekdays && <li> PON-PET: {weekdays}</li>}
            {sat && <li>SUB: {sat}</li>}
            {sun && <li>NED: {sun}</li>}
        </ul>
        )};

export default WorkTime;