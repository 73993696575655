import React, {useState} from 'react'
import {ChevronLeftIcon} from '@heroicons/react/24/solid'
import $ from 'jquery';

const defaultBtnStyle = 'w-full flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-gray-100 border-2\ ' +
    'rounded-lg hover:bg-green-800 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'

export default function(props) {
    let [open, setOpen] = useState(false);
    const toggleForm = () => {
        $('#contact-form').slideToggle()
        setOpen((previous) => !previous);
    }
    return (
            <button
                onClick={toggleForm}
                className={props.style ? props.style : defaultBtnStyle}
            >
                Putem kontakt obrasca
                <ChevronLeftIcon
                    className={`${
                        open ? 'transform -rotate-90' : ''
                    } w-7 h-7 text-white`}
                />
            </button>
    )
}
